export const sendParticipant = async (data) => {
  let response = {};

  try {
    response = await fetch('https://api.decroly.com.br/events/1/participants', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  } catch (err) {
    response = err;
    response.status = 400;
  }

  return response;
};
