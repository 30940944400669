export const getFormattedCPF = (cpf) => {
  let strCPF = cpf.split('.');
  strCPF = strCPF.join('');
  strCPF = strCPF.split('-');
  strCPF = strCPF.join('');

  return strCPF;
};

export const getFormattedPhone = (phone) => {
  let strPhone = phone.split('-');
  strPhone = strPhone.join('');

  return strPhone;
};

const isValidCPF = (cpf) => {
  const strCPF = getFormattedCPF(cpf);

  let equal = true;
  let sum = 0;
  let remainder = 0;

  for (let i = 1; i <= 9; i += 1) {
    if (strCPF[i] !== strCPF[i - 1]) equal = false;
  }

  if (equal) {
    return false;
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(strCPF.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
  }
  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(strCPF.substring(10, 11), 10)) {
    return false;
  }

  return true;
};

const emailRegEx = /^[a-z0-9._-]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;

export const validateForm = (form) => {
  const validParams = {};

  if (form.name) {
    validParams.name = true;
  }
  if (form.cpf.length === 14 && isValidCPF(form.cpf)) {
    validParams.cpf = true;
  }
  if (form.email.match(emailRegEx)) {
    validParams.email = true;
  }
  if (form.phone.length >= 12) {
    validParams.phone = true;
  }

  return validParams;
};

export const phoneMask = (e) => {
  e.target.maxLength = 13;

  let { value } = e.target;
  if (!value.match(/^(\d{2}) (\d{5})-(\d{4})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{2})(\d)/, '$1 $2');
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
  }

  return value;
};

export const cpfMask = (e) => {
  e.target.maxLength = 14;

  let { value } = e.target;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
  }

  return value;
};
