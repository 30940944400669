import React from 'react';

import Home from './pages/Home';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

global.jQuery = require('jquery');
require('bootstrap/dist/js/bootstrap');

function App() {
  return <Home />;
}

export default App;
