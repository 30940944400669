export const winners = [
  {
    id: 1,
    image: 'colocado-1',
    title: '1° colocado',
    name: 'Christian Ferreira',
  },
  {
    id: 2,
    image: 'colocado-2',
    title: '2° colocado',
    name: 'Karoline Oliveira',
  },
  {
    id: 3,
    image: 'colocado-3',
    title: '3° colocado',
    name: 'Wesley Cruz',
  },
  {
    id: 4,
    image: 'colocado-4',
    title: '4° colocado',
    name: 'Marcelo Bertolato',
  },
  {
    id: 5,
    image: 'colocado-5',
    title: '5° colocado',
    name: 'Barbara Ferreira',
  },
  {
    id: 6,
    image: 'colocado-6',
    title: '6° colocado',
    name: 'Lorena Piva',
  },
  {
    id: 7,
    image: 'colocado-7',
    title: '7° colocado',
    name: 'Victor Pedro',
  },
  {
    id: 8,
    image: 'colocado-8',
    title: '8° colocado',
    name: 'Victoria Soares',
  },
  {
    id: 9,
    image: 'colocado-9',
    title: '9° colocado',
    name: 'Alexandre Monteiro',
  },
  {
    id: 10,
    image: 'colocado-10',
    title: '10° colocado',
    name: 'Giovanna Moreira',
  },
];
