export const events = [
  {
    id: 1,
    date: '30/08/2021',
    thing: 'Lançamento do Edital e Abertura das Inscrições',
  },
  {
    id: 2,
    date: '08/10/2021',
    thing: 'Prazo Final da Inscrição',
  },
  {
    id: 3,
    date: '15/10/2021',
    thing: 'Prazo Final para Entrega da Proposta',
  },
  {
    id: 4,
    date: '08/11/2021',
    thing: 'Publicação dos Resultados',
  },
];
