export const questions = [
  {
    id: 1,
    text: 'Qualquer pessoa pode participar?',
    answer:
      'Não, o concurso é destinado apenas a estudantes e profissionais de arquitetura e urbanismo e engenharia civil.',
  },
  {
    id: 2,
    text: 'É permitida a inscrição de equipes?',
    answer: 'Não, a participação é exclusivamente individual.',
  },
  {
    id: 5,
    text: 'Posso ter um professor ou profissional orientador da proposta?',
    answer:
      'Não, a participação é exclusivamente individual. Portanto, é proibida a participação de arquitetos ou outros profissionais como orientadores ou consultores.',
  },
  {
    id: 6,
    text: 'Quanto custa a inscrição? Estudante paga?',
    answer:
      'O custo da inscrição é informado na página e no Edital do concurso. Todos os participantes devem pagar a taxa de inscrição para participar do concurso.',
  },
  {
    id: 8,
    text: 'Efetuei o pagamento da inscrição, como saber se o pagamento foi confirmado?',
    answer:
      'O pagamento é confirmado em até 2 (dois) dias úteis após ter sido realizado. Quando este for confirmado, você receberá um e-mail. Caso demore mais que 2 (dois) dias úteis para seu pagamento ser confirmado, entre em contato pelo e-mail concurso@projetou.com.br.',
  },
  {
    id: 16,
    text: 'Todos os participantes recebem certificado, ou apenas os vencedores? ',
    answer:
      'Sim, todos os participantes inscritos e que enviarem suas propostas recebem certificado. O certificado é emitido digitalmente e enviado por e-mail mediante o término do concurso.',
  },
  {
    id: 17,
    text: 'Paguei a minha inscrição mas não enviei o projeto, tenho direito a reembolso?',
    answer:
      'Não. A Projetou, na medida permitida pelas leis aplicáveis, não reembolsa pagamentos, a não ser nos casos em que o pagamento tenha sido realizado em até 7 dias antes da solicitação.',
  },
  {
    id: 18,
    text: 'Ainda estou com dúvidas, como entro em contato?',
    answer: 'concurso@projetou.com.br',
  },
];
