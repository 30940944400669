import React, { useEffect, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';

import { validateForm, getFormattedCPF, getFormattedPhone, cpfMask, phoneMask } from '../../utils/forms';
import { sendParticipant } from '../../api/participant';
import { events } from '../../utils/calendar';
import { questions } from '../../utils/FAQ';
import { winners } from '../../utils/winners';

import banner from '../../assets/images/banner.png';
import logo from '../../assets/images/logo.png';
import projetou from '../../assets/images/projetou.png';
import '../../assets/css/forms.css';
import '../../assets/css/winners.css';

const Home = () => {
  const [name, setName] = useState('');
  const [cpf, setCPF] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [nameInvalid, setNameInvalid] = useState(false);
  const [cpfInvalid, setCPFInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);

  const [hasMounted, setHasMounted] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalLink, setModalLink] = useState('');
  const [success, setSuccess] = useState(false);

  const requirePath = require.context('../../assets/images', true);
  const loadImage = (image) => requirePath(`./${image}`).default;

  const handleSend = async (e) => {
    e.preventDefault();

    if (isSending) return;
    setIsSending(true);

    const valid = validateForm({ name, cpf, email, phone });
    if (!valid.name || !valid.cpf || !valid.email || !valid.phone) {
      return;
    }

    const data = {
      name,
      cpf: getFormattedCPF(cpf),
      email,
      phone: getFormattedPhone(phone),
      eventId: 1,
      schoolId: 2,
    };

    await sendParticipant(data).then(async (response) => {
      if (response.status === 200) {
        const body = await response.json();
        const date = moment(new Date(body.bill.due_at)).format('DD-MM-YYYY');

        setModalTitle('Inscrição feita com sucesso!');
        setModalBody(
          `O pagamento no valor de R$ 35,00 deve ser feito até o dia ${date} e pode ser acessado através do botão abaixo. `
        );
        setModalLink(body.bill.url);
        setSuccess(true);
      } else if (response.status === 409) {
        const body = await response.json();

        setModalTitle('Sua inscrição já foi registrada');
        setModalBody(
          `Caso ainda não tenha efetuado o pagamento clique no botão abaixo para prosseguir e confirmar sua inscrição no 1º Concurso Cultural da Projetou.`
        );
        setModalLink(body.bill.url);
        setSuccess(true);
      } else {
        setModalTitle('Sua inscrição não foi realizada');
        setModalBody(
          'Um erro ocorreu. Por favor, tente novamente ou entre em contato pelo e-mail concurso@projetou.com.br.'
        );
        setSuccess(false);
      }

      setIsSending(false);
      $('#message').modal('show');
    });
  };

  useEffect(() => {
    if (hasMounted) {
      const valid = validateForm({ name, cpf, email, phone });
      setNameInvalid(false);

      if (!valid.name) {
        setNameInvalid(true);
      }
    }
    setHasMounted(true);
  }, [name]);

  useEffect(() => {
    if (hasMounted) {
      const valid = validateForm({ name, cpf, email, phone });
      setCPFInvalid(false);

      if (!valid.cpf) {
        setCPFInvalid(true);
      }
    }
  }, [cpf]);

  useEffect(() => {
    if (hasMounted) {
      const valid = validateForm({ name, cpf, email, phone });
      setEmailInvalid(false);

      if (!valid.email) {
        setEmailInvalid(true);
      }
    }
  }, [email]);

  useEffect(() => {
    if (hasMounted) {
      const valid = validateForm({ name, cpf, email, phone });
      setPhoneInvalid(false);

      if (!valid.phone) {
        setPhoneInvalid(true);
      }
    }
  }, [phone]);

  return (
    <div className='content'>
      <div id='message' className='modal fade' data-backdrop='static' role='dialog'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{modalTitle}</h5>
              <button type='button' className='close' onClick={() => $('#message').modal('hide')}>
                &times;
              </button>
            </div>
            <div className='modal-body'>
              <p>{modalBody}</p>
            </div>
            <div className='modal-footer'>
              {success ? (
                <a href={modalLink}>
                  <button type='button' className='btn btn-primary'>
                    Pagar inscrição
                  </button>
                </a>
              ) : (
                <button type='button' className='btn btn-secondary' onClick={() => $('#message').modal('hide')}>
                  Fechar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <section id='banner' className='banner'>
        <div className='background' style={{ backgroundImage: `url(${banner})` }} />
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='logo'>
                  <a href='https://www.projetou.com.br/' target='_blank' rel='noreferrer'>
                    <img src={logo} alt='logo' />
                  </a>
                </div>
                <div className='title'>
                  <h1>1&deg; Concurso de Arquitetura e Engenharia</h1>
                </div>
                <div className='subtitle'>
                  <h2>Escola de Arquitetura Projetou</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='menu' className='navigation'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='menus'>
                  <ul className='nav nav-pills justify-content-center'>
                    <li className='nav-item menu'>
                      <a className='nav-link' href='#inscricao'>
                        Inscrição
                      </a>
                    </li>
                    <li className='nav-item menu'>
                      <a
                        className='nav-link'
                        href='https://decroly-storage.s3.sa-east-1.amazonaws.com/decroly/courses/101422/extra-content/EDITAL%20CONCURSO%20PROJETOU%20(3).pdf'
                      >
                        Edital
                      </a>
                    </li>
                    <li className='nav-item menu'>
                      <a className='nav-link' href='#cronograma'>
                        Cronograma
                      </a>
                    </li>
                    <li className='nav-item manu'>
                      <a className='nav-link' href='#faq'>
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='premiacoes' className='awards'>
        <div className='container'>
          <div className='row'>
            <h3>Premiações</h3>

            <div className='col-md-4'>
              <div className='box'>
                <div className='title'>
                  <h4>1º colocado</h4>
                </div>
                <div className='award'>
                  <h5>R$ 1.500,00</h5>
                </div>
                <div className='description'>
                  <p>
                    + 1 ano de acesso a plataforma projetou <br />+ publicação no blog da projetou
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='box'>
                <div className='title'>
                  <h4>2º colocado</h4>
                </div>
                <div className='award'>
                  <h5>R$ 750,00</h5>
                </div>
                <div className='description'>
                  <p>
                    + 1 ano de acesso a plataforma projetou <br />+ publicação no blog da projetou
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='box'>
                <div className='title'>
                  <h4>3º colocado</h4>
                </div>
                <div className='award'>
                  <h5>R$ 500,00</h5>
                </div>
                <div className='description'>
                  <p>
                    + 1 ano de acesso a plataforma projetou <br />+ publicação no blog da projetou
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='box'>
                <div className='description'>
                  <p>4° ao 10° Colocado: 1 ano de acesso a plataforma projetou + publicação no blog da projetou</p>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='box'>
                <div className='description'>
                  <p>Todos os participantes receberão certificado de participação valendo horas complementares.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='tema' className='light'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='title'>
                  <h3>Tema</h3>
                </div>
                <div className='description'>
                  <p>
                    A Projetou é a maior plataforma de cursos online de Arquitetura, Engenharia Civil e Design de
                    Interiores do Brasil, capacitando mais de 80 mil alunos.
                  </p>
                  <p>
                    O 1° Concurso da Projetou convida alunos e profissionais de arquitetura e engenharia civil a
                    desenvolver um anteprojeto para a sede física da Projetou com espaços que influenciam positivamente
                    alunos e colaboradores da Projetou.
                  </p>
                  <p>
                    Além do impacto positivo no bem-estar e na qualidade de vida, alguns temas como inclusão social,
                    acessibilidade, sustentabilidade ambiental, soluções tecnológicas e visionárias têm sido cada vez
                    mais relevantes em nossa sociedade, portanto, é desejável que sejam considerados pelos
                    participantes.
                  </p>
                  <p>Para mais informações sobre o tema e modelo do concurso acesse o Edital.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='inscricao' className='light'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='box'>
                <div className='title'>
                  <h3>Vencedores</h3>
                </div>
                <div className='winners'>
                  <div className='row'>
                    {winners.map((winner) => (
                      <div className='col-lg-4 col-md-6' key={winner.id}>
                        <a href={loadImage(`${winner.image}.jpg`)}>
                          <div className='winner'>
                            <div className='winner-image'>
                              <img src={loadImage(`${winner.image}-thumb.jpg`)} alt={winner.title} />
                            </div>
                            <div className='winner-content'>
                              <div className='winner-title'>{winner.title}</div>
                              <div className='winner-name'>{winner.name}</div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='description d-none'>
                  <p>
                    As inscrições para o 1° Concurso de Arquitetura e Engenharia da Projetou estão oficialmente
                    encerradas. Lembre-se que a entrega deverá ocorrer até às <b>23h59 do dia 15/10/2021</b>.
                  </p>
                  <p>
                    O seu número de inscrição foi enviado para o email cadastrado! Caso você não tenha recebido, por
                    favor entre em contato com o nosso suporte:{' '}
                    <a href='mailto:concurso@projetou.com.br'>concurso@projetou.com.br</a>
                  </p>
                  <p>
                    Todos os detalhes de como deverá ser feito a entrega podem ser encontrados no{' '}
                    <a href='https://decroly-storage.s3.sa-east-1.amazonaws.com/decroly/courses/101422/extra-content/EDITAL%20CONCURSO%20PROJETOU%20(3).pdf'>
                      Edital
                    </a>
                    , assim como será enviado um email com todas as instruções no dia 11/10/21.
                  </p>
                  <p>Caso você tenha alguma dúvida, estamos à disposição em nosso suporte!</p>
                </div>
                <div className='form d-none'>
                  <form onSubmit={handleSend}>
                    <div className='form-group'>
                      <label htmlFor='name'>Nome *</label>
                      <input
                        type='text'
                        className={`form-control ${nameInvalid ? 'is-invalid' : ''}`}
                        id='name'
                        placeholder='Maria Silva Campos'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <div className='invalid-feedback'>Nome é obrigatório.</div>
                    </div>
                    <div className='row'>
                      <div className='form-group col-md-4'>
                        <label htmlFor='cpf'>CPF *</label>
                        <input
                          type='text'
                          className={`form-control ${cpfInvalid ? 'is-invalid' : ''}`}
                          id='cpf'
                          placeholder='000.000.000-00'
                          value={cpf}
                          onChange={(e) => setCPF(cpfMask(e))}
                          required
                        />
                        <div className='invalid-feedback'>CPF inválido.</div>
                      </div>
                      <div className='form-group col-md-4'>
                        <label htmlFor='email'>Email *</label>
                        <input
                          type='email'
                          className={`form-control ${emailInvalid ? 'is-invalid' : ''}`}
                          id='email'
                          placeholder='exemplo@domain.com'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <div className='invalid-feedback'>Email inválido.</div>
                      </div>
                      <div className='form-group col-md-4'>
                        <label htmlFor='phone'>Telefone *</label>
                        <input
                          type='phone'
                          className={`form-control ${phoneInvalid ? 'is-invalid' : ''}`}
                          id='phone'
                          placeholder='61 98888-8888'
                          value={phone}
                          onChange={(e) => setPhone(phoneMask(e))}
                          required
                        />
                        <div className='invalid-feedback'>Telefone inválido.</div>
                      </div>
                    </div>
                    <div className='form-check'>
                      <input type='checkbox' className='form-check-input' id='check-1' required />
                      <label className='form-check-label' htmlFor='check-1'>
                        Afirmo que enviarei um projeto de autoria própria. *
                      </label>
                    </div>
                    <div className='form-check'>
                      <input type='checkbox' className='form-check-input' id='check-2' required />
                      <label className='form-check-label' htmlFor='check-2'>
                        Disponibilizo os direitos autorais, intelectuais e técnicos da minha proposta à Projetou. *
                      </label>
                    </div>
                    <div className='form-check'>
                      <input type='checkbox' className='form-check-input' id='check-3' required />
                      <label className='form-check-label' htmlFor='check-3'>
                        Disponibilizo o uso de informações e dados para utilização comercial da Projetou. *
                      </label>
                    </div>

                    <button type='submit' className='btn btn-primary full-mobile' disabled={isSending}>
                      {isSending ? <i className='fas fa-circle-notch fa-spin fa-fw' /> : <span>Inscrever</span>}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='cronograma' className='dark'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='title'>
                  <h3>Cronograma</h3>
                </div>
                <div className='dates d-flex'>
                  <ul className='calendar'>
                    {events.map((event) => (
                      <li key={event.id} className='event row'>
                        <div className='time col-md-4'>
                          <span>{event.date}</span>
                        </div>
                        <div className='thing col-md-8'>
                          <center>
                            <span>{event.thing}</span>
                          </center>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='faq' className='light'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='title'>
                  <h3>Perguntas Frequentes</h3>
                </div>
                <div className='faq'>
                  <ul className='questions'>
                    {questions.map((question) => (
                      <li key={question.id} className='question'>
                        <div className='text'>
                          <button
                            type='button'
                            className='collapsed'
                            data-toggle='collapse'
                            data-target={`#collapse-${question.id}`}
                          >
                            <span>{question.text}</span>
                            <i className='fas fa-plus' />
                            <i className='fas fa-minus' />
                          </button>
                        </div>
                        <div className='answer collapse' id={`collapse-${question.id}`}>
                          <span>{question.answer}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='footer' className='light footer'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12'>
              <div className='box'>
                <div className='title'>
                  <h3>Realização</h3>
                </div>
                <div className='logos'>
                  <a href='https://www.projetou.com.br/' target='_blank' rel='noreferrer'>
                    <img src={projetou} className='projetou' alt='projetou' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
